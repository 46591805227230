import React, { Component } from 'react';
import { Field } from 'redux-form';
import BounceLoader from 'react-spinners/BounceLoader';
import {
  states,
  suffixes,
} from '../../common/lib/generateStates';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import {
  email,
  phone,
  normalizePhone,
  normalizeZip,
  required,
  noNumbers,
  state,
  zipcode,
  getAlphabeticOrder,
  cityMaxLength,
  validateDob,
  normalizeDob,
  noPeriods,
  noSpecialChars,
} from '../../common/lib/validation';

import { getError } from '../../common/lib/getError';
import { CITY_HELP_TEXT } from '../../common/lib/constants';

import '../../../style/index.css';
import './updateProfile.css';

 
class UpdateProfileForm extends Component {
  render() {
    const {
      submitFormFn,
      updateProfileSuccess,
      updateProfileFailed,
      formSubmissionError,
      errorDetail,
      statusCode,
      isFetching,
      signOutAllFn,
      formRef,
    } = this.props;
    const showSignOutAll = false;
    return (
      <form
        className="needs-validation"
        noValidate
        ref={formRef}
      >
        <div className="personal-info-wrap">
          <h3 className="form-header">Personal Information</h3>
          <Field
            name="firstName"
            type="text"
            component={RenderField}
            label="Legal First Name*"
            validate={[required, noNumbers]}
            normalize={noPeriods}
          />
          <Field
            name="middleName"
            type="text"
            component={RenderField}
            label="Middle Name"
            validate={noNumbers}
            normalize={noPeriods}
          />
          <Field
            name="lastName"
            type="text"
            component={RenderField}
            label="Last Name*"
            validate={[required, noNumbers]}
            normalize={noPeriods}
          />
          <Field
            name="suffix"
            type="select"
            options={suffixes}
            component={RenderField}
            label="Suffix"
            defaultSelectValue="Select Suffix"
          />
          <Field
            name="streetNumber"
            type="text"
            component={RenderField}
            label="House/Building Number*"
            validate={required}
            placeholder="Ex: 123"
            normalize={noSpecialChars}
          />
          <Field
            name="street"
            type="text"
            component={RenderField}
            label="Street Name*"
            validate={required}
            placeholder="Ex: Main Street"
          />
          <Field
            name="addressLine2"
            type="text"
            component={RenderField}
            label="Apartment/Floor/Other"
            placeholder="Ex: Apartment 2, Floor 3, etc (Optional)"
          />
          <Field
            name="city"
            type="text"
            component={RenderField}
            label="City/Town*"
            validate={[cityMaxLength, required]}
            helpText={CITY_HELP_TEXT}
          />
          <Field
            name="state"
            type="select"
            component={RenderField}
            label="State*"
            options={getAlphabeticOrder(states)}
            validate={state}
            defaultSelectValue="Select State"
          />
          <Field
            name="zip"
            type="text"
            component={RenderField}
            label="Zip Code*"
            validate={zipcode}
            normalize={normalizeZip}
          />
          <Field
            name="phone"
            type="text"
            component={RenderField}
            label="Phone Number*"
            placeholder="(XXX) XXX-XXXX"
            validate={phone}
            normalize={normalizePhone}
            prepend="phone"
            className="has-prepend"
          />
          <Field
            name="birthdate"
            type="text"
            component={RenderField}
            label="Date of Birth*"
            normalize={normalizeDob}
            placeholder="mm/dd/yyyy"
            validate={validateDob}
            inputmode="tel"
          />
        </div>
        <div className="personal-info-wrap">
          <h3 className="form-header">Account Setup</h3>
          {showSignOutAll &&
            <div className="sign-out-all-wrap">
              <div className="sign-out-all-copy-wrap">
                <p className="sign-out-all-copy">Sign out of all devices</p>
                <small className="text-muted">Sign out of all sessions, on all devices</small>

              </div>
            </div>
          }
          <Field
            name="email"
            type="text"
            component={RenderField}
            autocorrect="off"
            autocapitalize="none"
            label="Email Address*"
            validate={email}
            prepend="mail"
            className="has-prepend"
            fieldId="sso-email"
            disabled
          />
          <div className="button-group-wrapper">
            {showSignOutAll &&
              <Button
                label="Sign Out"
                onClickFn={signOutAllFn}
                className="nyl-button critical"
              />
            }
            <div className="button-wrap">
              {isFetching ?
                <div className="loading-wrapper">
                  <BounceLoader
                    color="#084267"
                  />
                </div>
                :
                <Button
                  label="Update Profile"
                  onClickFn={submitFormFn}
                  className="nyl-button"
                />
                }
              {updateProfileFailed &&
              <p className="submit-error" dangerouslySetInnerHTML={{ __html: getError(errorDetail, statusCode) }} />
                }
              {updateProfileSuccess &&
              <p className="submit-message">Profile updated successfully!</p>
                }
              {formSubmissionError &&
              <p className="submit-error">All inputs must be valid in order to submit the form.</p>
                }
            </div>
          </div>
        </div>
      </form>
    );
  }
}

export default UpdateProfileForm;
