
/* eslint-disable no-shadow */
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import queryString from 'query-string';
import _ from 'lodash';
import { getMigrateExistingUserFormValues } from '../../redux/selectors/migrateExistingUserFormSelector';
import { RedirectTimer } from '../../common/components/RedirectTimer';
import {
  fetchProfile,
  migrateProfile,
  resetAutocompleteMigrate,
  selectAddressMigrate,
} from '../../redux/actions';
import {
  email,
  phone,
  required,
  zipcode,
  validatePassword,
  cityMaxLength,
  validateDob,
  ssn4,
} from '../../common/lib/validation';
import MigrateExistingUserForm from './MigrateExistingUserForm';
import { scrollToFirstErrorField } from '../../common/lib/scrollToFirstErrorField';
import { clearTimeoutRef, setComponentTimeout } from '../../common/lib/setComponentTimeout';

const formName = 'migrateExistingUser';
const formSelector = formValueSelector(formName);

import '../../../style/index.css';
import './migrateExistingUser.css';

const mapStateToProps = (state) => ({
  migrateProfileForm: getMigrateExistingUserFormValues(state),
  password: formSelector(state, 'password'),
  ssn4: formSelector(state, 'ssn4'),
  noSsn4: formSelector(state, 'noSsn4'),
  errorDetail: state.migrateProfileReducer.errorDetail,
  migrateProfileFailed: state.migrateProfileReducer.migrateProfileFailed,
  migrateProfileSuccess: state.migrateProfileReducer.migrateProfileSuccess,
  fetchProfileFailed: state.migrateProfileReducer.fetchProfileFailed,
  profile: state.migrateProfileReducer.profile,
  initialValues: state.migrateProfileReducer.profile,
  statusCode: state.migrateProfileReducer.statusCode,
  formErrors: getFormSyncErrors(formName)(state),
  selectedAddress: state.migrateProfileReducer.selectedAddress,
  selectedAddressParsed: state.migrateProfileReducer.selectedAddressParsed,
  isFetching: state.migrateProfileReducer.isFetching,
});

const mapDispatchToProps = {
  migrateProfile,
  fetchProfile,
  selectAddressMigrate,
  resetAutocompleteMigrate,
};

const isValid = (values) => {
  let valid = true;
  if (required(values.firstName)
    || required(values.lastName)
    || required(values.streetNumber)
    || required(values.street)
    || required(values.city)
    || cityMaxLength(values.city)
    || required(values.state)
    || zipcode(values.zip)
    || phone(values.phone)
    || required(values.birthdate)
    || validateDob(values.birthdate)
    || email(values.email)
    || (values.ssn4 && ssn4(values.ssn4))
    || (!values.ssn4 && !values.noSsn4)
    || (values.password && validatePassword(values.password))
    || (values.password && values.password !== values.confirmPassword)) {
    valid = false;
  }
  return valid;
};

class MigrateExistingUserView extends Component {
  static propTypes = {
    migrateProfileForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    migrateProfile: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleMigrateProfileSubmit = this.handleMigrateProfileSubmit.bind(this);
    this.selectAddressFn = this.selectAddressFn.bind(this);
    this.formRef = React.createRef();
    this.timeoutRef = React.createRef();
    this.state = { formSubmissionError: false };
  }

  componentDidMount() {
    const { fetchProfile } = this.props;
    fetchProfile();
  }

  componentWillUnmount() {
    clearTimeoutRef(this.timeoutRef);
  }

  handleMigrateProfileSubmit(e) {
    e.preventDefault();
    const {
      migrateProfileForm,
      migrateProfile,
      selectedAddressParsed,
      touch,
      formErrors,
      history,
    } = this.props;
    if (isValid(migrateProfileForm)) {
      migrateProfile(migrateProfileForm, selectedAddressParsed, history.push);
      this.setState({ formSubmissionError: false });
    } else {
      touch(...Object.keys(formErrors));
      this.setState({ formSubmissionError: true }, () => {
        setComponentTimeout(this.timeoutRef, () => {
          scrollToFirstErrorField(formErrors, this.formRef);
        }, 500);
      });
    }
  }

  selectAddressFn(address) {
    const { selectAddressMigrate } = this.props;
    selectAddressMigrate({ address });
  }

  render() {
    const {
      password,
      ssn4,
      noSsn4,
      migrateProfileSuccess,
      migrateProfileFailed,
      fetchProfileFailed,
      errorDetail,
      profile,
      statusCode,
      selectedAddress,
      resetAutocompleteMigrate,
      isFetching,
    } = this.props;
    const { formSubmissionError } = this.state;
    const accessTokenExpiredErr = errorDetail === 'Access Token has expired' || errorDetail === 'Invalid Access Token';
    const queryParams = queryString.parse(window.location.search);
    const clientId = _.get(queryParams, 'clientId', false);
    return (
      <div className="migrate-existing-user row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          {(fetchProfileFailed || migrateProfileFailed) && accessTokenExpiredErr &&
            <RedirectTimer
              redirectUri={`/login?callbackUri=/idv/migrate&clientId=${clientId}`}
              timer={5000}
              message="Your token has expired. Redirecting you to log in again."
            />
          }
          {!fetchProfileFailed &&
            <MigrateExistingUserForm
              formRef={this.formRef}
              password={password}
              ssn4={ssn4}
              noSsn4={noSsn4}
              submitFormFn={this.handleMigrateProfileSubmit}
              migrateProfileSuccess={migrateProfileSuccess}
              migrateProfileFailed={migrateProfileFailed}
              formSubmissionError={formSubmissionError}
              errorDetail={errorDetail}
              profile={profile}
              statusCode={statusCode}
              selectedAddress={selectedAddress}
              resetAutocomplete={resetAutocompleteMigrate}
              selectAddressFn={this.selectAddressFn}
              isFetching={isFetching}
            />
          }
        </div>
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(reduxForm({
  form: formName,
  enableReinitialize: true,
})(MigrateExistingUserView));
