/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Component } from 'react';
import { Field } from 'redux-form';
import { isBrowser } from 'react-device-detect';
import RenderField from '../../common/components/Form/RenderField';
import { state } from '../../common/lib/validation';

import { idvTypes } from '../../common/lib/generateStates';
import Button from '../../common/components/Button';

import '../../../style/index.css';
import './govIdTypeSelection.css';

class GovIdTypeSelectionForm extends Component {
  render() {
    const { formRef, submitFormFn } = this.props;
    return (
      <form
        className="needs-validation"
        noValidate
        ref={formRef}
      >
        <div className="personal-info-wrap">
          <h3 className="form-header">Please select ID type</h3>
          <p>Use the drop down to select a valid form of Government Issued Photo Identification*</p>
          <Field
            name="govIdType"
            type="select"
            component={RenderField}
            label=""
            options={idvTypes}
            validate={state}
            defaultSelectValue="Select ID type"
          />
          <div className="flow-selection-wrap">
            <div className="mobile-flow">
              <Button
                label="Continue on mobile"
                onClickFn={(e) => submitFormFn(e, 'mobile')}
                className="nyl-button"
              />
            </div>
            {isBrowser &&
              <div className="desktop-flow">
                <a
                  className="continue-with-browser-link"
                  onClick={(e) => submitFormFn(e, 'desktop')}
                >
                  Continue on Browser
                </a>
              </div>
            }
          </div>
        </div>
      </form>
    );
  }
}

export default GovIdTypeSelectionForm;
