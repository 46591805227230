
/* eslint-disable react/require-default-props */
/* eslint-disable no-shadow */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  reduxForm,
  formValueSelector,
  getFormSyncErrors,
} from 'redux-form';
import { getRegisterFormValues } from '../../redux/selectors/registerFormSelector';
import {
  registerUser,
  fetchAddresses,
  selectAddress,
  resetAutocomplete,
} from '../../redux/actions';
import { gtagEvent } from '../../common/lib/analyticsEventManager';

import RegisterForm from './RegisterForm';
import { scrollToFirstErrorField } from '../../common/lib/scrollToFirstErrorField';
import { clearTimeoutRef, setComponentTimeout } from '../../common/lib/setComponentTimeout';

const formName = 'register';

const formSelector = formValueSelector(formName);

import '../../../style/index.css';
import './register.css';

const mapStateToProps = (state) => ({
  registerForm: getRegisterFormValues(state),
  password: formSelector(state, 'password'),
  ssn4: formSelector(state, 'ssn4'),
  noSsn4: formSelector(state, 'noSsn4'),
  addressInput: formSelector(state, 'addressAutocomplete'),
  address: formSelector(state, 'address'),
  addresses: state.registerReducer.addresses,
  addressAutocompleteIsFetching: state.registerReducer.addressAutocompleteIsFetching,
  addressErrorDetail: state.registerReducer.addressErrorDetail,
  errorDetail: state.registerReducer.errorDetail,
  registrationFailed: state.registerReducer.registrationFailed,
  registrationSuccess: state.registerReducer.registrationSuccess,
  statusCode: state.registerReducer.statusCode,
  formErrors: getFormSyncErrors(formName)(state),
  selectedAddress: state.registerReducer.selectedAddress,
  selectedAddressParsed: state.registerReducer.selectedAddressParsed,
  isFetching: state.registerReducer.isFetching,
});

const mapDispatchToProps = {
  registerUser,
  fetchAddresses,
  selectAddress,
  resetAutocomplete,
};

@connect(mapStateToProps, mapDispatchToProps)
class RegisterView extends Component {
  static propTypes = {
    registerForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    registerUser: PropTypes.func,
     
    fetchAddresses: PropTypes.func,
    selectAddress: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleRegisterSubmit = this.handleRegisterSubmit.bind(this);
    this.selectAddressFn = this.selectAddressFn.bind(this);
    this.formRef = React.createRef();
    this.timeoutRef = React.createRef();
    this.state = { formSubmissionError: false };
  }

  componentDidMount() {
    gtagEvent('DC-5139115/colle0/colle00-+standard');
  }

  componentWillUnmount() {
    clearTimeoutRef(this.timeoutRef);
  }

  handleRegisterSubmit(e) {
    e.preventDefault();
    const {
      registerForm,
      registerUser,
      selectedAddressParsed,
      valid,
      touch,
      formErrors,
      history,
    } = this.props;

    const ssn4OptionSelected = registerForm.ssn4 || registerForm.noSsn4;
    if (valid && ssn4OptionSelected) {
      registerUser(registerForm, selectedAddressParsed, history.push);
      this.setState({ formSubmissionError: false });
    } else {
      touch(...Object.keys(formErrors));
      this.setState({ formSubmissionError: true }, () => {
        setComponentTimeout(this.timeoutRef, () => {
          scrollToFirstErrorField(formErrors, this.formRef);
        }, 500);
      });
    }
  }


  selectAddressFn(address) {
    const { selectAddress } = this.props;
    selectAddress({ address });
  }

  render() {
    const {
      password,
      ssn4,
      noSsn4,
      selectedAddress,
      registrationSuccess,
      registrationFailed,
      errorDetail,
      statusCode,
      resetAutocomplete,
      addressErrorDetail,
      location,
      isFetching,
      address,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <div key={location.pathname} className="register row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10 ">
          <RegisterForm
            formRef={this.formRef}
            password={password}
            ssn4={ssn4}
            noSsn4={noSsn4}
            selectedAddress={selectedAddress}
            submitFormFn={this.handleRegisterSubmit}
            selectAddressFn={this.selectAddressFn}
            registrationSuccess={registrationSuccess}
            registrationFailed={registrationFailed}
            formSubmissionError={formSubmissionError}
            errorDetail={errorDetail}
            statusCode={statusCode}
            resetAutocomplete={resetAutocomplete}
            addressErrorDetail={addressErrorDetail}
            isFetching={isFetching}
            address={address}
          />
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: formName })(RegisterView);
