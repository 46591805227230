import React, { Component } from 'react';
import { Field } from 'redux-form';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import { email } from '../../common/lib/validation';

import '../../../style/index.css';
import './forgotPassword.css';

 
class ForgotPasswordForm extends Component {
  render() {
    const {
      submitFormFn,
      formSubmissionError,
      submitting,
      formRef,
    } = this.props;
    return (
      <form
        className="needs-validation"
        noValidate
        ref={formRef}
      >
        <div className="personal-info-wrap">
          <Field
            name="email"
            type="text"
            component={RenderField}
            autocorrect="off"
            autocapitalize="none"
            label="Email Address"
            validate={email}
            prepend="mail"
            className="has-prepend"
            fieldId="forgot-password-email"
          />
          <div className="button-wrap">
            <Button
              label="Email My Link"
              onClickFn={submitFormFn}
              className={`nyl-button ${submitting ? 'disabled' : ''}`}
              id="forgot-password-button"
            />
            {formSubmissionError &&
              <p className="submit-error">All inputs must be valid in order to submit the form.</p>
            }
          </div>
        </div>
      </form>
    );
  }
}

export default ForgotPasswordForm;
