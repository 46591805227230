import React, {
  Component,
  Fragment,
} from 'react';
import {
  Field,
  reduxForm,
  formValueSelector,
} from 'redux-form';
import Lottie from 'react-lottie';
import BounceLoader from 'react-spinners/BounceLoader';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import { connect } from 'react-redux';
import {
  normalizeOtp,
  fixedLengthSix,
} from '../../common/lib/validation';
import * as animationData from './otp-send-anim.json';

const formSelector = formValueSelector('otp-code-confirmation');
// Is there a validation fn for the codes in the common lib we should be using?
// import { validatePassword, isEmpty } from '../../common/lib/validation';

const mapStateToProps = (state) => ({
  otp: formSelector(state, 'otp'),
  otpCooldown: state.commonReducer.otpCooldown,
  showOtpRequestAnimation: state.commonReducer.showOtpRequestAnimation,
});

const mapDispatchToProps = {};

@connect(mapStateToProps, mapDispatchToProps)
class EnterCodeForm extends Component {
  constructor(props) {
    super(props);
    this.state = { otpInputValid: false };
  }

  _onSubmit(e) {
    e.preventDefault();
  }

  componentWillReceiveProps(nextProps) {
    const { otp } = nextProps;
    this.setState((state) => ({
      ...state,
      otpInputValid: /^[0-9]{6,6}$/.test(otp),
    }));
  }


  render() {
    const {
      phoneNumber,
      requestOtp,
      verifyOtp,
      errorState,
      verifyOtpIsFetching,
      userVerifyFailed,
      otpCooldown,
      showOtpRequestAnimation,
    } = this.props;
    const { otpInputValid } = this.state;

    const defaultOptions = {
      loop: false,
      autoplay: true,
      animationData,
      rendererSettings: { preserveAspectRatio: 'xMidYMid slice' },
    };

    return (
        <form
          className="needs-validation"
          noValidate
          onSubmit={this._onSubmit}
        >
          <div className="confirm-otp-wrap">
            <h3 className="confirm-otp-header">{showOtpRequestAnimation ? 'New code sent' : 'Enter your code'}</h3>
            {showOtpRequestAnimation &&
              <div className="anim-wrap">
                <Lottie
                  options={defaultOptions}
                  height={60}
                  width={60}
                />
              </div>
            }
            <p className="confirm-otp-text" >{showOtpRequestAnimation ? `We have just sent a message to ${phoneNumber} with a code for you to enter.` : `We have just sent a message to ${phoneNumber} with a code for you to enter here:`}</p>
            {!showOtpRequestAnimation &&
              <Fragment>
                <Field
                  name="otp"
                  type="tel"
                  component={RenderField}
                  validate={fixedLengthSix}
                  normalize={normalizeOtp}
                  placeholder="Enter code here"
                  inputmode="tel"
                />
                <div className="button-wrap">
                  {verifyOtpIsFetching ?
                    <div className="loading-wrapper">
                      <BounceLoader
                        color="#084267"
                      />
                    </div>
                    :
                    <Fragment>
                      <Button
                        label="Continue"
                        onClickFn={verifyOtp}
                        className={`${!otpInputValid ? 'nyl-button disabled' : 'nyl-button'}`}
                        disabled={!otpInputValid}
                        id="confirm-otp-button"
                      />
                      <br />
                      {userVerifyFailed &&
                        <Button
                          label="Resend Code"
                          onClickFn={(e) => { requestOtp('sms', e, true); }}
                          className="nyl-button"
                          id="confirm-otp-verify-failed-resend-button"
                        />
                      }
                    </Fragment>
                  }
                  {errorState && <p className="submit-error">{errorState}</p>}
                </div>
                {otpCooldown
                  ?
                    <div className="button-wrap otp-delay-wrap">
                      <p className="otp-not-received ">Note: your code may take up to 30s to be delivered</p>
                    </div>
                  :
                    <div className="button-wrap">
                      <p className="otp-not-received">
                        If you have not received your code{' '}
                        <button
                          className="unreceived-otp-link"
                          onClick={(e) => { requestOtp('voice', e, true); }}
                          id="confirm-otp-retry-call-button"
                        >
                          retry call
                        </button>
                        {' '}or{' '}
                        <button
                          className="unreceived-otp-link"
                          onClick={(e) => { requestOtp('sms', e, true); }}
                          id="confirm-otp-retry-text-button"
                        >
                          retry text
                        </button>
                      </p>
                    </div>
                }
              </Fragment>
            }
          </div>
        </form>
    );
  }
}

export default reduxForm({ form: 'otp-code-confirmation' })(EnterCodeForm);
