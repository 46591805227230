
/* eslint-disable react/require-default-props */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { getLoginFormValues } from '../../redux/selectors/loginFormSelector';
import { loginUser } from '../../redux/actions';
import LoginForm from './LoginForm';
import { scrollToFirstErrorField } from '../../common/lib/scrollToFirstErrorField';
import { clearTimeoutRef, setComponentTimeout } from '../../common/lib/setComponentTimeout';

import '../../../style/index.css';
import './login.css';

const formName = 'login';

const mapStateToProps = (state) => ({
  loginForm: getLoginFormValues(state),
  formErrors: getFormSyncErrors(formName)(state),
  loginFailed: state.loginReducer.loginFailed,
  loginSuccess: state.loginReducer.loginSuccess,
  statusCode: state.loginReducer.statusCode,
  errorDetail: state.loginReducer.errorDetail,
  isFetching: state.loginReducer.isFetching,
});

const mapDispatchToProps = { loginUser };

@connect(mapStateToProps, mapDispatchToProps)
class LoginView extends Component {
  static propTypes = {
    loginForm: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool,
    ]),
    loginUser: PropTypes.func,
  }

  constructor(props) {
    super(props);
    this.handleLoginSubmit = this.handleLoginSubmit.bind(this);
    this.formRef = React.createRef();
    this.timeoutRef = React.createRef();
    this.state = { formSubmissionError: false };
  }

  componentWillUnmount() {
    clearTimeoutRef(this.timeoutRef);
  }

  handleLoginSubmit(e) {
    e.preventDefault();
    const {
      formErrors,
      loginForm,
       
      loginUser,
      history,
      touch,
      valid,
    } = this.props;
    if (valid) {
      loginUser(loginForm, history.push);
      this.setState({ formSubmissionError: false });
    } else {
      touch(...Object.keys(formErrors));
      this.setState({ formSubmissionError: true }, () => {
        setComponentTimeout(this.timeoutRef, () => {
          scrollToFirstErrorField(formErrors, this.formRef);
        }, 500);
      });
    }
  }

  render() {
    const {
      loginSuccess,
      loginFailed,
      statusCode,
      errorDetail,
      isFetching,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <div className="login row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          <LoginForm
            submitFormFn={this.handleLoginSubmit}
            loginSuccess={loginSuccess}
            loginFailed={loginFailed}
            formSubmissionError={formSubmissionError}
            statusCode={statusCode}
            errorDetail={errorDetail}
            isFetching={isFetching}
          />
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: formName })(LoginView);
