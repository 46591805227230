import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  reduxForm,
  getFormSyncErrors,
  formValueSelector,
} from 'redux-form';
import { getConfirmResetPasswordFormValues } from '../../redux/selectors/confirmResetPasswordSelector';
import { confirmResetPassword } from '../../redux/actions';
import ConfirmResetPasswordForm from './ConfirmResetPasswordForm';
import { scrollToFirstErrorField } from '../../common/lib/scrollToFirstErrorField';
import { clearTimeoutRef, setComponentTimeout } from '../../common/lib/setComponentTimeout';

const formName = 'confirmResetPassword';
const formSelector = formValueSelector(formName);

import '../../../style/index.css';
import './confirmResetPassword.css';

const mapStateToProps = (state) => ({
  password: formSelector(state, 'password'),
  confirmResetPasswordForm: getConfirmResetPasswordFormValues(state),
  formErrors: getFormSyncErrors(formName)(state),
  confirmResetPasswordFailed: state.confirmResetPasswordReducer.confirmResetPasswordFailed,
  errorDetail: state.confirmResetPasswordReducer.errorDetail,
  statusCode: state.confirmResetPasswordReducer.statusCode,
  confirmResetPasswordSuccess: state.confirmResetPasswordReducer.confirmResetPasswordSuccess,
});

const mapDispatchToProps = { confirmResetPassword };

@connect(mapStateToProps, mapDispatchToProps)
class ConfirmResetPasswordView extends Component {
  constructor(props) {
    super(props);
    this.handleConfirmResetPasswordSubmit = this.handleConfirmResetPasswordSubmit.bind(this);
    this.formRef = React.createRef();
    this.timeoutRef = React.createRef();
    this.state = { formSubmissionError: false };

  }

  componentWillUnmount() {
    clearTimeoutRef(this.timeoutRef);
  }

  handleConfirmResetPasswordSubmit(e) {
    e.preventDefault();
    if (this.state.submitting) return;
    this.setState({ submitting: true });

    const {
      confirmResetPasswordForm,
       
      confirmResetPassword,
      formErrors,
      touch,
      valid,
    } = this.props;

    if (valid) {
      confirmResetPassword(confirmResetPasswordForm);
      this.setState({ formSubmissionError: false });
    } else {
      this.setState({
        formSubmissionError: true,
        submitting: false,
      });
    }
  }

  render() {
    const {
      confirmResetPasswordFailed,
      confirmResetPasswordSuccess,
      errorDetail,
      statusCode,
      password,
    } = this.props;
    const { formSubmissionError } = this.state;
    return (
      <div className="confirm-reset-password row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          {confirmResetPasswordSuccess ? (
            <div className="alert alert-success mt-5">
              <h4 className="alert-heading text-center text-uppercase">Success!</h4>
              <p className="mt-2 text-center">Your password has been reset.</p>
            </div>
          ) : (
            <ConfirmResetPasswordForm
              formRef={this.formRef}
              submitFormFn={this.handleConfirmResetPasswordSubmit}
              confirmResetPasswordSuccess={confirmResetPasswordSuccess}
              confirmResetPasswordFailed={confirmResetPasswordFailed}
              errorDetail={errorDetail}
              statusCode={statusCode}
              formSubmissionError={formSubmissionError}
              password={password}
              submitting={this.state.submitting}
            />
          )}
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: formName })(ConfirmResetPasswordView);
