/**
 * Clears the timeout referenced by the given timeoutRef.
 *
 * @param {Object} timeoutRef - A React Ref containing a reference to the timeout.
 * @param {number} [timeoutRef.current] - The current timeout ID, if it is defined.
 */
export const clearTimeoutRef = (timeoutRef) => {
  if (timeoutRef.current) {
    clearTimeout(timeoutRef.current);
    timeoutRef.current = null;
  }
};

/**
 * Sets a timeout for a component and ensures any existing timeout (if it
 * exists) is cleared before setting a new one.
 * This is useful for component safety to avoid having the code in the timeout
 * invoking after the component has unmounted.
 *
 * @param {Object} timeoutRef - A React Ref containing a reference to the timeout.
 * @param {number} [timeoutRef.current] - The current timeout ID, if it is defined.
 * @param {Function} callback - The function to be executed after the timeout.
 * @param {number} [timeout] - The (optional) duration (in milliseconds) of the timeout.
 * @returns {number} - The ID of the timeout that has been created.
 */
export const setComponentTimeout = (timeoutRef, callback, timeout) => {
  clearTimeoutRef(timeoutRef);
  timeoutRef.current = setTimeout(() => {
    callback();
    clearTimeoutRef(timeoutRef)
  }, timeout);
  return timeoutRef.current;
};
