import React, { Component } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import { connect } from 'react-redux';
import { getFormSyncErrors, reduxForm } from 'redux-form';
import { isMobileOnly } from 'react-device-detect';
import { Base64 } from 'js-base64';
import { getGovIdTypeSelectionFormValues } from '../../redux/selectors/govIdTypeSelectionFormSelector';
import { selectGovIdType } from '../../redux/actions';
import { fetchUser } from '../../services/auth';
import GovIdTypeSelectionForm from './GovIdTypeSelectionForm';
import { scrollToFirstErrorField } from '../../common/lib/scrollToFirstErrorField';
import { clearTimeoutRef, setComponentTimeout } from '../../common/lib/setComponentTimeout';

import '../../../style/index.css';
import './govIdTypeSelection.css';

 
const constructEnv = () => {
  const { host } = window.location;
  const urlArr = host.split('.');
  if (urlArr && urlArr.length > 0 && urlArr[0].includes('sso')) {
    return urlArr[0];
  }
};

const formName = 'govIdTypeSelection';

const mapStateToProps = (state) => ({
  formErrors: getFormSyncErrors(formName)(state),
  govIdSelectionForm: getGovIdTypeSelectionFormValues(state),
});

const mapDispatchToProps = { selectGovIdType };

@connect(mapStateToProps, mapDispatchToProps)
class GovIdTypeSelectionView extends Component {
  constructor(props) {
    super(props);
    this.handleGovIdTypeSelectionSubmit = this.handleGovIdTypeSelectionSubmit.bind(this);
    this.formRef = React.createRef();
    this.timeoutRef = React.createRef();
    this.state = { formSubmissionError: false };
  }

  componentWillUnmount() {
    clearTimeoutRef(this.timeoutRef);
  }

  handleGovIdTypeSelectionSubmit(e, type) {
    e.preventDefault();
    const {
      formErrors,
      govIdSelectionForm,
       
      selectGovIdType,
    } = this.props;
    const queryParams = queryString.parse(window.location.search);
    const callbackUri = _.get(queryParams, 'callbackUri', false);
     
    const clientId = _.get(queryParams, 'clientId', false);
    const user = fetchUser();
    const accessToken = _.get(user, 'accessToken', false);
    const ssoEnv = __CONFIG__.env;
    const redirectUri = __CONFIG__.iddwGovIdRedirectUri;
    const userEmail = _.get(user.identity, 'email', false);
    const loginHint = JSON.stringify({
      credential: userEmail,
      fname: user.firstName,
      lname: user.lastName,
    });
    const encodedEmail = encodeURIComponent(loginHint);
    if (govIdSelectionForm && (govIdSelectionForm.govIdType === 'US Driver\'s License/ID Card' || govIdSelectionForm.govIdType === 'International Passport')) {
      selectGovIdType(govIdSelectionForm, type);

      const isStageOrProd = ssoEnv === 'production' || ssoEnv === 'stage';
      const baseUrl = `https://${isStageOrProd ? '' : 'preprod.'}verify.nylservices.net`;
      const path = `${isStageOrProd ? 'prod' : 'preprod'}-axn/axn/oauth2/authorize`;
      let iddwClientId;
      const stateQueryParamObj = {
        accessToken,
        callbackUri,
        clientId,
      };
      if (type === 'mobile' && !isMobileOnly) {
        if (govIdSelectionForm.govIdType === 'US Driver\'s License/ID Card') {
          iddwClientId = __CONFIG__.iddwToMobileUsdlClientId;
          stateQueryParamObj.govIdType = 'usdl-mobile';
        } else if (govIdSelectionForm.govIdType === 'International Passport') {
          iddwClientId = __CONFIG__.iddwToMobilePassportClientId;
          stateQueryParamObj.govIdType = 'usp-mobile';
        }
      } else if (type === 'mobile' && isMobileOnly) {
        if (govIdSelectionForm.govIdType === 'US Driver\'s License/ID Card') {
          iddwClientId = __CONFIG__.iddwRemainUsdlClientId;
          stateQueryParamObj.govIdType = 'usdl-desktop';
        } else if (govIdSelectionForm.govIdType === 'International Passport') {
          iddwClientId = __CONFIG__.iddwRemainPassportClientId;
          stateQueryParamObj.govIdType = 'usp-desktop';
        }
      } else if (type === 'desktop') {
        if (govIdSelectionForm.govIdType === 'US Driver\'s License/ID Card') {
          iddwClientId = __CONFIG__.iddwRemainUsdlClientId;
          stateQueryParamObj.govIdType = 'usdl-desktop';
        } else if (govIdSelectionForm.govIdType === 'International Passport') {
          iddwClientId = __CONFIG__.iddwRemainPassportClientId;
          stateQueryParamObj.govIdType = 'usp-desktop';
        }
      }
      if (iddwClientId) {
        const stateQueryParamStr = JSON.stringify(stateQueryParamObj);
        const encodedStateQueryParam = encodeURIComponent(Base64.encode(stateQueryParamStr));
        const destinationUrl = `${baseUrl}/${path}?client_id=${iddwClientId}&redirect_uri=${redirectUri}&scope=openid+country.US&response_type=code&state=${encodedStateQueryParam}&login_hint=${encodedEmail}`;
        window.location.replace(destinationUrl);
      } else {
        this.setState({ formSubmissionError: false });
      }
    } else {
      this.setState({ formSubmissionError: true }, () => {
        setComponentTimeout(this.timeoutRef, () => {
          scrollToFirstErrorField(formErrors, this.formRef);
        }, 500);
      });
    }
  }

  render() {
    const { formSubmissionError } = this.state;
    return (
      <div className="login row justify-content-center">
        <div className="col-xl-7 col-lg-8 col-md-8 col-sm-10">
          <GovIdTypeSelectionForm
            submitFormFn={this.handleGovIdTypeSelectionSubmit}
            formSubmissionError={formSubmissionError}
          />
        </div>
      </div>
    );
  }
}

export default reduxForm({ form: formName })(GovIdTypeSelectionView);
