/**
 * Finds the topmost field element with an error in the form.
 * Note, that this works in conjunction with `RenderField` component, due to the
 * reliance on the `data-field-name` attribute.
 * @param {Object} formErrors - An object containing form error information, where keys are field names and values are error messages.
 * @param {Object} [formRef] - A reference to the form element. If no current formRef, defaults to `document`.
 * @returns {Object} An object containing the topmost field element and its top position relative to the document.
 * @returns {HTMLElement|null} [return.element] - The topmost field element with an error, or null if no error elements are found.
 * @returns {number|null} [return.top] - The top position of the topmost field element with an error, or null if no error elements are found.
 */
const getTopFieldElement = (formErrors, formRef) => {
  const formErrorKeys = Object.keys(formErrors);
  const { scrollY } = window;
  let topFieldObj = {
    element: null,
    top: null,
  };
  const container = formRef.current || document;
  formErrorKeys.forEach((fieldName) => {
    const fieldElement = container.querySelector(`[data-field-name="${fieldName}"]`);
    if (fieldElement) {
      const { top } = fieldElement.getBoundingClientRect();
      const fieldElementTop = top + scrollY;
      if (!topFieldObj.top || fieldElementTop < topFieldObj.top) {
        topFieldObj = {
          element: fieldElement,
          top: fieldElementTop,
        };
      }
    }
  });
  return topFieldObj;
};

export const scrollToFirstErrorField = (formErrors, formRef) => {
  const topFieldObj = getTopFieldElement(formErrors, formRef);
  if (topFieldObj.element) {
    window.scroll({ top: topFieldObj.top, behavior: 'smooth' });
  }
};
