import React, { Fragment } from 'react';
import Button from '../../common/components/Button';

const ConfirmAccount = ({ phoneNumber, requestOtp }) => {
  const paragraphMessage = 'We will send you a one-time verification code via text or phone call with the number you have entered. This helps us confirm your identity and secure your account.';
  return (
    <Fragment>
      <h3 className="confirm-otp-header">Confirm Account</h3>
      <p className="confirm-otp-paragraph">
        {paragraphMessage}
      </p>
      <p className="confirm-otp-number-text">
        Your Phone Number:
      </p>
      <h3 className="confirm-view-phone-number">
        {phoneNumber}
      </h3>
      <div className="button-wrap">
        <Button
          label="Text Me"
          onClickFn={() => { requestOtp('sms'); }}
          className="nyl-button"
          id="confirm-otp-text-button"
        />
        <Button
          label="Call Me"
          onClickFn={() => { requestOtp('voice'); }}
          className="nyl-button"
          id="confirm-otp-voice-button"
        />
      </div>
    </Fragment>
  );
};

export default ConfirmAccount;
