
/* eslint-disable react/prefer-stateless-function */
import React, { Component } from 'react';
import _ from 'lodash';
import queryString from 'query-string';
import BounceLoader from 'react-spinners/BounceLoader';
import { Field } from 'redux-form';
import RenderField from '../../common/components/Form/RenderField';
import Button from '../../common/components/Button';
import {
  email,
  required,
} from '../../common/lib/validation';

import { getError } from '../../common/lib/getError';

import '../../../style/index.css';
import './login.css';

class LoginForm extends Component {
  render() {
    const {
      submitFormFn,
      loginFailed,
      formSubmissionError,
      statusCode,
      errorDetail,
      isFetching,
      formRef,
    } = this.props;
    const queryParams = queryString.parse(window.location.search);
    const clientId = _.get(queryParams, 'clientId', false);
    return (
      <form
        className="needs-validation"
        noValidate
        ref={formRef}
      >
        <div className="personal-info-wrap">
          <h3 className="form-header">Account Login</h3>
          <Field
            name="email"
            type="text"
            component={RenderField}
            autocorrect="off"
            autocapitalize="none"
            label="Email Address"
            validate={email}
            prepend="mail"
            className="has-prepend"
            fieldId="sso-email"
          />
          <Field
            name="password"
            type="password"
            component={RenderField}
            label="Password"
            validate={required}
          />
          <div className="button-wrap">
            {isFetching ?
              <div className="loading-wrapper">
                <BounceLoader
                  color="#084267"
                />
              </div>
            :
              <Button
                label="Log In"
                onClickFn={submitFormFn}
                className="nyl-button"
                id="login-button"
              />
            }
            {!isFetching && (loginFailed && !formSubmissionError) &&
              <p className="submit-error" dangerouslySetInnerHTML={{ __html: getError(errorDetail, statusCode) }} />
            }
            {formSubmissionError &&
              <p className="submit-error">There is a problem with the data you entered, please try again.</p>
            }
            <p className="forgot-password"><a href={`/reset-password?clientId=${clientId}`}>Forgot Password?</a> It happens.</p>

          </div>
        </div>
      </form>
    );
  }
}

export default LoginForm;