const copyDefaults = {
  login: {
    header: 'WELCOME. LOG IN HERE.',
    body: 'Use this page to log in to your New York Lottery account.',
    preLinkText: 'You can log in with your Collect ‘N Win credentials, but Official NY Lottery App members will need to ',
    linkText: 'register for a new account',
    postLinkText: '.',
    linkUrl: '/register',
  },
  register: {
    header: 'WELCOME TO THE NEW YORK LOTTERY REGISTRATION PAGE.',
    body: 'Creating an account will let you take advantage of everything the New York Lottery has to offer. All fields with asterisks are required for registration. ',
    preLinkText: 'Already have an account? Go to the ',
    linkText: 'Login page',
    postLinkText: '.',
    linkUrl: '/login',
  },
  resetPassword: {
    header: 'Reset your password here',
    body: 'Please enter your email and you\'ll receive a message with instructions to reset your password.',
  },
  confirmResetPassword: {
    header: 'Reset password confirmation',
    body: 'Please enter your new password and confirm in the fields below. Then press RESET PASSWORD',
  },
  verifyEmail: {
    header: '',
    body: '',
  },
  updateProfile: {
    header: 'Update Profile',
    body: 'Update your profile here.',
  },
  migrateProfile: {
    header: 'Please verify your details',
    body: 'In order to better protect your account, all New York Lottery associated accounts will use one login, everywhere! To keep it secure we are moving your data to one location.',
    line2: 'Please verify that the information below is correct and re-enter your address details.',
  },
  confirmOtp: {
    header: '',
    body: '',
  },
  verifySsn: {
    header: '',
    body: '',
  },
  migrationFailed: {
    header: '',
    body: '',
  },
  userDuplicated: {
    header: '',
    body: '',
  },
  selectGovIdType: {
    header: 'Welcome to the New York Lottery Registration Page',
    body: 'In order to set up your account, we will need to verify your identity with your US Driver\'s License/ID Card or International Passport.',
  },
  govIdResponse: {
    header: '',
    body: '',
  },
};

export const getCopy = (path) => {
  if (path.includes('login')) {
    return copyDefaults.login;
  } else if (path.includes('register')) {
    return copyDefaults.register;
  } else if (path.includes('reset-password') && !path.includes('confirm-')) {
    return copyDefaults.resetPassword;
  } else if (path.includes('confirm-reset-password')) {
    return copyDefaults.confirmResetPassword;
  } else if (path.includes('verify-email')) {
    return copyDefaults.verifyEmail;
  } else if (path.includes('idv/otp')) {
    return copyDefaults.confirmOtp;
  } else if (path.includes('idv/migrate')) {
    return copyDefaults.migrateProfile;
  } else if (path.includes('update-profile')) {
    return copyDefaults.updateProfile;
  } else if (path.includes('idv/ssn4')) {
    return copyDefaults.verifySsn;
  } else if (path.includes('idv/failed')) {
    return copyDefaults.migrationFailed;
  } else if (path.includes('idv/select-gov-id-type')) {
    return copyDefaults.selectGovIdType;
  } else if (path.includes('gov-id-response')) {
    return copyDefaults.govIdResponse;
  } else if (path.includes('user-duplicated')) {
    return copyDefaults.userDuplicated;
  }
  return copyDefaults.register;
};
